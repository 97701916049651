import { Button } from "react-bootstrap";
import metadataDB from "../storage/metadataDB";
import { toast } from "react-toastify";

export default function AboutPage(props) {
  return (
    <div>
      {/* <h1>关于</h1> */}
      <div>
        <h2>听笙阁</h2>
        <p>
          听笙阁，是一个怀揣激情和创意的声音创作团队，致力于打造一个引人入胜的声音的世界。我们深信，声音是一种强大的媒介，能够穿越时空，触及人心，唤起情感和回忆。因此，我们不仅仅是一个声音平台，更是一个情感的寄托和创意的舞台。
          在听笙阁，我们追求声音的多样性和创新性。我们相信，每个人都有独特的声音，都值得被聆听和发现。无论是悠扬的乐曲，还是深情的朗诵；无论是自然的声音，还是数字的合成，我们都将尽力呈现最优质、最引人入胜的声音体验。通过技术的不断探索和创新，我们希望能够为大家带来耳目一新的声音感知。
          作为一个充满活力和创造力的团队，我们热衷于挑战传统，突破界限。我们的声音作品不拘泥于固定的模式，而是不断地探索和尝试，将艺术与科技相融合，创造出独具个性的声音表达。我们希望能够激发人们的思维，引发共鸣，甚至可能改变人们对声音的认知和体验。
          我们热切期待与您共同探索声音的奇妙世界。如果您有任何问题、建议或合作意向，都欢迎随时与我们联系。您可以通过发送电子邮件至{" "}
          <a href="mailto:contact@crz97.com">contact@crz97.com</a>
          ，与我们取得联系。听笙阁，与您一同分享声音的美妙旅程！
        </p>
      </div>
      <div>
        <h4>版权投诉</h4>
        <p>
          版权投诉请发email到{" "}
          <a href="mailto:copyright@crz97.com">copyright@crz97.com</a>
        </p>
      </div>
      <div>
        <Button
          onClick={async () => {
            const total = await metadataDB.deleteAllCaches();
            toast.info(`已成功删除${total}条缓存！！`);
          }}
        >
          清除所有缓存
        </Button>
      </div>
    </div>
  );
}
