import "react-circular-progressbar/dist/styles.css";

import { useContext, useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { CircularProgressbar } from "react-circular-progressbar";
import { BsFillTrash3Fill } from "react-icons/bs";
import { FcDownload } from "react-icons/fc";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import AudioPlayerList from "../components/audioPlayerList";
import PaginationWrapper from "../components/paginationWrapper";
import { NOVEL_TYPE } from "../configs/createNovelConfig";
import AppContext from "../context/app-context";
import novelService from "../services/novelService";
import localFileStorage from "../storage/localFileStorage";
import metadataDB from "../storage/metadataDB";
import AudioControlBar from "../widgets/audioControlBar";
import NovelInfoDisplay from "../widgets/novelInfoDisplay";
import NovelPageList from "../widgets/novelPageList";
import { Tab, Tabs } from "react-bootstrap";
import { Slider } from "@mui/material";
import FontSizeSlider from "../widgets/pageContentDisplayer";
import PageContentDisplayer from "../widgets/pageContentDisplayer";
import { toast } from "react-toastify";

const MAX_DOWNLOAD_SONG_COUNT = 5;
export const PAGINATION_SIZE = 50;

export default function NovelDetailPage() {
  const { id } = useParams("id");
  const { setLoading, userProfile } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const paginationPage = (searchParams.get("page") || 1) - 1;
  const [novelDetails, setNovelDetails] = useState({});
  const [novelPageListMapping, setNovelPageListMapping] = useState({});
  const [displayingList, setDisplayingList] = useState([]);
  const [downloadStatus, setDownloadStatus] = useState({});
  const [downloadList, setDownloadList] = useState([]);
  const [audioList, setAudioList] = useState([]);
  const [playHistory, setPlayHistory] = useState([]);
  // const [paginationPage, setPaginationPage] = useState(0);
  const [audioInstance, setAudioInstance] = useState(null);
  const [currentPlaying, setCurrentPlaying] = useState({});
  const [localDownloadedList, setLocalDownloadedList] = useState({});
  const [dbPageList, setDbPageList] = useState([]);
  const [displayMulu, setDisplayMulu] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isCurrentlyPlaying, setIsCurrentlyPlaying] = useState(false);
  const [fontSize, setFontSize] = useState(15);
  const playSongByIndex = (index) => {
    if (audioInstance != null) {
      audioInstance.playByIndex(index);
      setTimeout(() => audioInstance.play(), 500);
    }
  };
  const { title, novelType, attachmentUrl, lastestPageNumber = 0 } = novelDetails;

  const totalPageCount = Math.round(lastestPageNumber / PAGINATION_SIZE) + 1;
  async function downloadPageFromOnline(pageItem) {
    const { id, pageNumber } = pageItem;
    const pageId = metadataDB.getPageItemId(id, pageNumber);
    setDownloadStatus((prev) => ({ ...prev, [pageId]: 0 }));
    setDownloadList((prev) => [...prev, pageItem]);
    const {
      item,
      item: {
        attachment: { type },
      },
    } = await novelService.getPageDetails({
      id,
      pageNumber,
    });
    const recordItem = {
      ...item,
      id: pageId,
      novelId: id,
    };
    delete recordItem.attachmentUrl;
    setDbPageList((prev) => {
      const filteredList = prev.filter((prevItem) => prevItem.pageNumber != pageNumber);
      return [...filteredList, recordItem];
    });
    metadataDB.putItemToNovelPageData(recordItem);
    const { attachmentUrl } = item;
    setDownloadList((prev) => prev.filter((item) => item.pageNumber != pageNumber));
    const audioSrc = await localFileStorage.getFileOrDownloadedUrl(pageId, attachmentUrl, type, ({ id, precentage }) => {
      setDownloadStatus((prev) => ({ ...prev, [pageId]: precentage }));
    });
    setLocalDownloadedList((prev) => ({ ...prev, [pageId]: true }));
    setDownloadStatus((prev) => {
      delete prev[pageId];
      return prev;
    });
    return audioSrc;
  }

  useEffect(() => {
    async function getLocalThenOnlineDetails() {
      const localDetails = await metadataDB.getNovelMetadataById(id);
      const covImgUrl = await localFileStorage.getBlobUrlFromLocal(id);
      const { title } = localDetails || {};
      document.title = `听笙阁 ${title}`;
      setNovelDetails({ ...localDetails, attachmentUrl: covImgUrl });
      novelService.getNovelDetailsById({
        id,
        callback: async ({ data }) => {
          if (data) {
            const { item } = data;
            const {
              id,
              attachmentUrl,
              title: titleOnline,
              attachment: { type },
            } = item;
            document.title = `听笙阁 ${titleOnline}`;
            if (!covImgUrl) {
              const coverImgSrc = await localFileStorage.getFileOrDownloadedUrl(id, attachmentUrl, type, () => {});
              item[attachmentUrl] = coverImgSrc;
            }
            metadataDB.putItemToNovelMetadata(item);
            setNovelDetails((prev) => ({ ...prev, ...item }));
          }
        },
      });
    }
    getLocalThenOnlineDetails();
  }, []);
  useEffect(() => {
    async function getHistroy() {
      const playHistory = await metadataDB.getLocalPlayHistory(id);
      playHistory.sort((i1, i2) => new Date(i2.insertedDate).getTime() - new Date(i1.insertedDate).getTime());
      setPlayHistory(playHistory);
    }
    getHistroy();
  }, []);
  useEffect(() => {
    // if current playing change, then pre download the next one
    const { pageNumber } = currentPlaying;
    const nextPage = pageNumber + 1;
    const nexPageId = metadataDB.getPageItemId(id, nextPage);
    // if (nextPage <= lastestPageNumber && !localDownloadedList[nexPageId] && !downloadStatus[nexPageId]) {
    //   downloadPageFromOnline({ id, pageNumber: pageNumber + 1 });
    // }
    if (
      displayingList.length > 0 &&
      displayingList[displayingList.length - 1].pageNumber === pageNumber + 1 &&
      pageNumber + 2 <= lastestPageNumber
    ) {
      setSearchParams({ page: paginationPage + 2 });
      setTimeout(() => {
        setSearchParams({ page: paginationPage + 1 });
      }, 2000);
    }
    // if (displayingList.length > 0 && displayingList[displayingList.length - 1].pageNumber === pageNumber - 1) {
    //   setSearchParams({ page: paginationPage + 2 });
    // }
  }, [currentPlaying]);
  useEffect(() => {
    if (id) {
      const effectPage = paginationPage;
      if (effectPage <= totalPageCount) {
        if (novelPageListMapping[effectPage]) {
          setDisplayingList(novelPageListMapping[effectPage]);
        } else {
          async function getNovelFromLocalOrOnline() {
            setLoading(true);
            try {
              const localPageList = await metadataDB.getNovelPageById(id);
              localPageList
                .sort((a, b) => a.pageNumber - b.pageNumber)
                .map((item) => {
                  delete item.attachmentUrl;
                  return item;
                });
              setDbPageList(localPageList);
              const localMap = localPageList.reduce((accu, pageItem) => {
                const { novelId, pageNumber } = pageItem;
                const localPaginationPage = Math.floor((pageNumber - 1) / PAGINATION_SIZE);
                if (accu.hasOwnProperty(localPaginationPage)) {
                  accu[localPaginationPage].push({ ...pageItem, id: novelId });
                } else {
                  accu[localPaginationPage] = [{ ...pageItem, id: novelId }];
                }
                return accu;
              }, {});
              if (!localMap.hasOwnProperty(effectPage) || localMap[paginationPage].length !== PAGINATION_SIZE) {
                setLoading(true);
                novelService.getNovelsPagesById({
                  id,
                  lastEvaluatedKey: effectPage * PAGINATION_SIZE,
                  callback: ({ data = {}, err } = {}) => {
                    setLoading(false);
                    if (err) {
                      toast.error("Unable to load page details: " + err);
                    }
                    const { items = [] } = data;
                    if (effectPage === paginationPage) {
                      setDisplayingList(items);
                    }
                    setNovelPageListMapping((prev) => ({
                      ...prev,
                      [effectPage]: items,
                    }));
                    items.forEach((item) => {
                      const { id, pageNumber, title } = item;
                      const pageItem = {
                        ...item,
                        id: metadataDB.getPageItemId(id, pageNumber),
                        title,
                        pageNumber,
                        novelId: id,
                      };
                      metadataDB.putItemToNovelPageData(pageItem);
                    });
                  },
                });
              } else {
                setNovelPageListMapping(localMap);
                setDisplayingList(localMap[effectPage] || []);
              }
              setLoading(false);
            } catch (e) {
              setLoading(false);
              console.error("Unable to load pages", e);
              setDisplayingList([]);
            }
          }
          getNovelFromLocalOrOnline();
        }
      }
    }
  }, [paginationPage, totalPageCount]);

  useEffect(() => {
    async function checkDownloadedAudios() {
      const downloadedAudios = await Promise.all(
        displayingList.map(async (displayItem) => {
          const { id, pageNumber } = displayItem;
          const audioId = metadataDB.getPageItemId(id, pageNumber);
          const audioFile = await localFileStorage.getBlobFromLocal(audioId);
          return { id: audioId, downloaded: Boolean(audioFile) };
        })
      );
      const downloadMapper = downloadedAudios.reduce((acc, item) => {
        const { id, downloaded } = item;
        acc[id] = downloaded;
        return acc;
      }, {});
      setLocalDownloadedList(downloadMapper);
    }
    checkDownloadedAudios();
  }, [displayingList]);
  useEffect(() => {
    var newAudioList = [];
    for (var i = 0; i <= totalPageCount; i++) {
      if (novelPageListMapping[i]) {
        newAudioList = [...newAudioList, ...novelPageListMapping[i]];
      }
    }
    setAudioList(newAudioList);
  }, [novelPageListMapping]);

  // find default playing index
  const [{ pageNumber: lastPlayPageNumber = 1 } = {}] = playHistory || [];
  // console.log(audioList);
  const lastPlayItem = audioList.find((item) => item.pageNumber === lastPlayPageNumber);
  // console.log(lastPlayItem);
  const displayRows = displayingList.map((item) => {
    const { title, pageNumber } = item;
    const pageId = metadataDB.getPageItemId(id, pageNumber);
    const alreadyDownLoad = localDownloadedList[metadataDB.getPageItemId(id, pageNumber)];
    const isPending = downloadList.find((item) => item.pageNumber === pageNumber);
    const downloadDiv = (
      <div style={{ cursor: "pointer" }}>
        {isPending && <div>Pending</div>}
        {Object.keys(downloadStatus).includes(pageId) && (
          <div style={{ width: 30, height: 30 }}>
            <CircularProgressbar value={downloadStatus[pageId]} text={`${downloadStatus[pageId]}%`} />
          </div>
        )}
        {!Object.keys(downloadStatus).includes(pageId) && !isPending && (
          <div>{alreadyDownLoad ? <BsFillTrash3Fill /> : <FcDownload />}</div>
        )}
      </div>
    );
    var displayTitle = title;
    const hisItem = playHistory.find((his) => his.pageNumber === pageNumber);
    if (currentPlaying.pageNumber === pageNumber) displayTitle = "正在播放：" + title;
    if (hisItem) {
      const pect = Math.round((hisItem.currentTime / hisItem.duration) * 100);
      displayTitle = displayTitle + " 已播放" + pect + "%";
    }
    return [
      {
        value: displayTitle,
        currentlyPlaying: currentPlaying.pageNumber === pageNumber,
        onClick: () => {
          playSongByIndex(audioList.findIndex((item) => item.pageNumber === pageNumber));
          setCurrentPlaying(item);
        },
      },
      {
        value: downloadDiv,
        onClick: () => {
          if (alreadyDownLoad) {
            localFileStorage.deleteBlobFromLocal(pageId);
            setLocalDownloadedList((prev) => ({ ...prev, [pageId]: false }));
          } else {
            downloadPageFromOnline(item);
          }
        },
      },
    ];
  });
  const paginationComp = (
    <PaginationWrapper
      size={totalPageCount}
      active={paginationPage}
      onChange={(newPageNumber) => {
        setSearchParams({ page: newPageNumber + 1 });
      }}
    />
  );
  console.log(audioList);
  const { pageContent = "" } = dbPageList.find((dbItem) => dbItem.pageNumber === currentPlaying?.pageNumber) || {};
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => navigate("/")}>主页</Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => navigate(`/?novelType=${NOVEL_TYPE.find((item) => item.displayName === novelType)?.novelType}`)}
        >
          {novelType}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          margin: "20px 0",
        }}
      >
        <NovelInfoDisplay novelDetails={novelDetails} lastPlayItem={lastPlayItem} />

        <div>
          <AudioPlayerList
            playList={audioList}
            coverSrc={attachmentUrl}
            setCurrentPlaying={setCurrentPlaying}
            currentPlaying={currentPlaying}
            setPlayHistory={setPlayHistory}
            isCurrentlyPlaying={isCurrentlyPlaying}
            setIsCurrentlyPlaying={setIsCurrentlyPlaying}
            passAudioInstanceToParent={(instance) => {
              setAudioInstance(instance);
            }}
            getAudioSrcById={async (id, row) => {
              const { pageNumber } = row;
              // console.log("getAudioSrcById ", row);
              const pageId = metadataDB.getPageItemId(id, pageNumber);
              const url = await localFileStorage.getBlobUrlFromLocal(pageId);
              if (!url) {
                const { item } = await novelService.getPageDetails({
                  id,
                  pageNumber,
                });
                const { attachmentUrl, ...itemWithoutUrl } = item;
                const recordItem = {
                  ...itemWithoutUrl,
                  id: pageId,
                  novelId: id,
                };
                delete recordItem.attachmentUrl;
                metadataDB.putItemToNovelPageData(recordItem);
                setDbPageList((prev) => {
                  const filteredList = prev.filter((prevItem) => prevItem.pageNumber != pageNumber);
                  filteredList.push(recordItem);
                  return [...filteredList, recordItem];
                });
                return attachmentUrl;
              }
              return url;
            }}
          />
        </div>
        <AudioControlBar
          prev={audioInstance?.playPrev}
          // play={audioInstance?.togglePlay}
          play={() => {
            if (firstLoad) {
              if (isCurrentlyPlaying) {
                setFirstLoad(false);
                audioInstance?.togglePlay();
                return;
              }
              var defaultPlayIndex = 0;
              // console.log({ playHistory, audioInstance, audioList });
              if (playHistory.length > 0 && audioList.length > 0) {
                const curntIndex = audioList.findIndex((it) => it.pageNumber === playHistory[0].pageNumber);
                if (curntIndex >= 0) {
                  defaultPlayIndex = curntIndex;
                }
                if (audioInstance && firstLoad) {
                  // console.log("Set playing index ", defaultPlayIndex);
                  audioInstance.playByIndex(defaultPlayIndex);
                  // audioInstance.togglePlay();
                  setFirstLoad(false);
                  // audioInstance.currentTime = 20;
                }
              } else {
                console.log("Not history playing");
                audioInstance?.togglePlay();
              }
            } else audioInstance?.togglePlay();
          }}
          next={audioInstance?.playNext}
        />
        <Tabs
          defaultActiveKey={novelType}
          className="mb-3"
          onSelect={(e) => {
            setDisplayMulu(e === "mulu");
          }}
        >
          <Tab eventKey={"mulu"} title={"目录"} />
          {/* <Tab eventKey={"currentPage"} title={currentPlaying?.title || ""} /> */}
        </Tabs>
        {displayMulu && (
          <div>
            {paginationComp}
            <NovelPageList displayRows={displayRows} otherComponent={paginationComp} />
          </div>
        )}
        <PageContentDisplayer
          displayMulu={displayMulu}
          setFontSize={setFontSize}
          pageContent={pageContent}
          fontSize={fontSize}
        />
      </div>
    </div>
  );
}
