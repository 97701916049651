import { useContext, useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AppContext from "../context/app-context";
import Card from "react-bootstrap/Card";
import { Alert } from "react-bootstrap";
import { APP_ROUTE_LIST } from "../App";
import CollectionList from "../components/collectionList";
import { Form } from "react-bootstrap";
import novelService from "../services/novelService";
import { NOVEL_TYPE } from "../configs/createNovelConfig";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import metadataDB from "../storage/metadataDB";
import localFileStorage from "../storage/localFileStorage";
import { XUAN_HUAN_LANDING_CONFIGS } from "../configs/xuanhuanLanding";

export default function HomePage(props) {
  const {
    userProfile,
    setLoading,
    novelListTypeMap,
    setNovelListTypeMap,
    myPlayHistory,
  } = useContext(AppContext);
  const navigate = useNavigate();
  // const { novelType = "xuanhuan" } = useParams("novelType");

  const [searchParams, setSearchParams] = useSearchParams();
  const novelType = searchParams.get("novelType") || "xuanhuan";
  const [novelList, setNovelList] = useState(XUAN_HUAN_LANDING_CONFIGS);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (novelType === "myhistory") {
      setNovelList(myPlayHistory);
      return;
    }
    const disp = NOVEL_TYPE.find(
      (item) => item.novelType === novelType
    )?.displayName;
    if (novelListTypeMap[disp] === undefined) {
      setLoading(true);
      novelService.getNovelsByNovelType({
        novelType: disp,
        callback: async ({ data }) => {
          if (data) {
            const { items = [] } = data;
            const downloadedList = await Promise.all(
              items.map(async ({ attachmentUrl, ...novelData }) => {
                const {
                  id,
                  attachment: { type },
                } = novelData;
                metadataDB.putItemToNovelMetadata(novelData);
                var converSrc = await localFileStorage.getFileOrDownloadedUrl(
                  id,
                  attachmentUrl,
                  type,
                  () => {}
                );
                return { ...novelData, attachmentUrl: converSrc };
              })
            );
            setNovelListTypeMap((prev) => ({
              ...prev,
              [disp]: downloadedList,
            }));
            setNovelList(downloadedList);
          }
          setLoading(false);
        },
      });
    } else {
      setNovelList(novelListTypeMap[disp]);
    }
  }, [novelType, myPlayHistory]);
  return (
    <div>
      <div>
        <Tabs
          defaultActiveKey={novelType}
          className="mb-3"
          onSelect={(e) => {
            setSearchParams({ novelType: e });
          }}
        >
          {NOVEL_TYPE.map((item) => {
            const { displayName, novelType: localType } = item;
            return (
              <Tab
                eventKey={localType}
                key={localType}
                title={displayName}
                onSelect={(e) => {
                  console.log(e.target.eventKey);
                }}
              />
            );
          })}
          <Tab
            eventKey={"myhistory"}
            key={"myhistory"}
            title={"播放历史"}
            onSelect={(e) => {}}
          />
        </Tabs>
      </div>

      <div style={{ margin: "10px 0" }}>
        {/* <>
          <Form.Label>查找</Form.Label>
          <Form.Control
            type="text"
            value={query}
            onChange={(e) => {
              const val = e.target.value;
              setQuery(val);
            }}
          />
          <Form.Text></Form.Text>
        </> */}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <CollectionList collections={novelList} query={query} />
      </div>
      <div>
        {/* <Alert key={"info"} variant={"info"}>
          Note: You can save this website offline, which mean you can access
          some of the feature offline, including the music you downloaded. you
          can do this by: For Google Chrome (Android and iOS): Open the Chrome
          app on your mobile phone. Navigate to the website you want to save for
          offline viewing. Tap the three-dot menu icon in the top-right corner
          of the screen. Select "Download" from the menu. The webpage will be
          downloaded and saved to your device for offline viewing. For Safari
          (iOS): Open the Safari app on your iPhone or iPad. Navigate to the
          website you want to save for offline viewing. Tap the Share icon (a
          box with an arrow pointing upward) at the bottom of the screen. Scroll
          through the options and tap "Add to Reading List." The webpage will be
          saved for offline viewing in the Reading List.
        </Alert> */}
      </div>
    </div>
  );
}
