export default function NovelInfoDisplay(props) {
  const {
    lastPlayItem,
    novelDetails: {
      attachmentUrl,
      title,
      author,
      description,
      lastestPageNumber,
      lastModifiedDate,
    } = {},
  } = props;
  return (
    <div className="novel-info">
      <img
        style={{}}
        className="coverImageInfo"
        src={attachmentUrl}
        alt={title}
      />
      <div style={{ margin: "10px" }}>
        <h2> {title} </h2>
        <h3>作者：{author} </h3>
        <p>{description}</p>
        <p>
          最新章节： 第{lastestPageNumber}章, 更新时间： {lastModifiedDate}
        </p>
        {lastPlayItem && <p>上次收听到：{lastPlayItem.title}</p>}
      </div>
    </div>
  );
}
