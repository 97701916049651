import Card from "react-bootstrap/Card";

import { useNavigate } from "react-router-dom";

export default function CollectionList(props) {
  const { collections, query = "" } = props;

  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {collections
        .filter((c) => {
          if (query.length !== 0) {
            const { title } = c;
            return title.includes(query);
          }
          return true;
        })
        .map((collection = {}) => {
          const {
            title,
            description,
            createdDate,
            id,
            attachmentUrl,
            lastModifiedDate,
            lastestPageNumber,
            playHistory = [],
          } = collection;
          const [{ title: epsoTitle } = {}] = playHistory;
          // console.log(playHistory[0].title);
          return (
            <div
              key={id}
              onClick={(e) => {
                navigate(`/details/${id}`);
              }}
              style={{ margin: "5px", cursor: "pointer" }}
            >
              <Card style={{ width: "8rem", height: "14rem" }}>
                <Card.Img
                  variant="top"
                  src={attachmentUrl}
                  style={{
                    width: "8rem",
                    height: "150px",
                    verticalAlign: "middle",
                    objectFit: "cover",
                  }}
                />
                <Card.Body>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    title={title}
                  >
                    {title}
                  </div>
                  <p style={{ textAlign: "center", fontSize: "9px" }}>
                    {epsoTitle
                      ? `继续播放${epsoTitle}`
                      : `更新到第${lastestPageNumber}章`}
                  </p>
                  {/* <Card.Title>{title}</Card.Title> */}
                  {/* <Card.Text>{description}</Card.Text> */}
                </Card.Body>
              </Card>
            </div>
          );
        })}
    </div>
  );
}
