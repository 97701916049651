import axios from "axios";
import Auth from "@aws-amplify/auth";
import { getUserJwtToken } from "../utils/helperFunctions";

const SVC_URL = "https://novel.service.crz97.com";
const API_KEY = "p58BTHm4P73HjK1ntyq2awwXtEALqvWd9AWvKrCh";

const client = axios.create({ baseURL: SVC_URL });

const API_PATH = {
  resource: "resource",
  novel: "novel",
  novelPage: "novelPage",
  pageDetail: "pageDetail",
  pages: "pages",
};

function handleError(err, callback) {
  console.error(err);
  callback && callback({ err });
}

async function createNovelMetadata({
  title,
  description,
  author,
  novelType,
  attachment,
  filesUploadCallback,
  userId,
  callback,
}) {
  // const file = attachments[0];
  var jwtToken = null;
  try {
    const user = await Auth.currentAuthenticatedUser();
    jwtToken = getUserJwtToken(user);
  } catch (e) {
    // console.log(e);
    return;
  }
  const { name, type, size } = attachment;
  const { preSignedURL, bucket, key } = await getPresignedUrl({
    // jwtToken,
    fileName: name,
    fileType: type,
  });
  const res = await axios.put(preSignedURL, attachment, {
    headers: { "Content-Type": attachment.type },
    onUploadProgress: (progressEvent) => {
      const uploadPercentage = Math.ceil(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      filesUploadCallback &&
        filesUploadCallback({ id: name, value: uploadPercentage });
    },
  });

  client
    .post(
      `${API_PATH.novel}`,
      {
        title,
        author,
        description,
        userId,
        novelType,
        attachment: { bucket, key, name, type, size },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken ? jwtToken : undefined,
        },
      }
    )
    .then((res) => {
      const {
        // data: { collections },
        data,
      } = res;
      callback({ data });
    })
    .catch((error) => handleError(error, callback));
}

async function getNovelsByNovelType({ novelType, callback }) {
  // const file = attachments[0];
  var jwtToken = null;
  try {
    const user = await Auth.currentAuthenticatedUser();
    jwtToken = getUserJwtToken(user);
    // console.log(jwtToken);
  } catch (e) {}

  client
    .get(`${API_PATH.novel}?novelType=${novelType}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: jwtToken ? jwtToken : undefined,
        "x-api-key": API_KEY,
      },
    })
    .then((res) => {
      const {
        // data: { collections },
        data,
      } = res;
      callback({ data });
    })
    .catch((error) => handleError(error, callback));
}

async function getNovelDetailsById({ id, callback }) {
  // const file = attachments[0];
  var jwtToken = null;
  try {
    const user = await Auth.currentAuthenticatedUser();
    jwtToken = getUserJwtToken(user);
    // console.log(jwtToken);
  } catch (e) {}

  client
    .get(`${API_PATH.novelPage}?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: jwtToken ? jwtToken : undefined,
        "x-api-key": API_KEY,
      },
    })
    .then((res) => {
      const { data } = res;
      callback({ data });
    })
    .catch((error) => handleError(error, callback));
}
async function getNovelsPagesById({ id, lastEvaluatedKey, callback }) {
  // const file = attachments[0];
  var jwtToken = null;
  try {
    const user = await Auth.currentAuthenticatedUser();
    jwtToken = getUserJwtToken(user);
  } catch (e) {}

  client
    .post(
      `${API_PATH.pages}`,
      {
        id,
        lastEvaluatedKey,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken ? jwtToken : undefined,
          "x-api-key": API_KEY,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ data });
    })
    .catch((error) => handleError(error, callback));
}

async function getPresignedUrl({ fileName, fileType }) {
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  return await client
    .get(`resource?fileName=${fileName}&fileType=${fileType}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: jwtToken,
      },
    })
    .then((res) => {
      const {
        data: { preSignedURL, bucket, key },
      } = res;
      return { preSignedURL, bucket, key };
    })
    .catch((error) => handleError(error));
}

async function createNovelPage({
  id,
  title,
  pageNumber,
  pageContent,
  userId,
  attachment,
  filesUploadCallback,
  callback,
}) {
  // const file = attachments[0];
  var jwtToken = null;
  try {
    const user = await Auth.currentAuthenticatedUser();
    jwtToken = getUserJwtToken(user);
  } catch (e) {
    // console.log(e);
    return;
  }
  const { name, type, size } = attachment;
  const { preSignedURL, bucket, key } = await getPresignedUrl({
    jwtToken,
    fileName: name,
    fileType: type,
  });
  const res = await axios.put(preSignedURL, attachment, {
    headers: { "Content-Type": attachment.type },
    onUploadProgress: (progressEvent) => {
      const uploadPercentage = Math.ceil(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      filesUploadCallback &&
        filesUploadCallback({ id: name, value: uploadPercentage });
    },
  });

  client
    .post(
      `${API_PATH.novelPage}`,
      {
        id,
        title,
        pageNumber,
        userId,
        pageContent,
        attachment: { bucket, key, name, type, size },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken ? jwtToken : undefined,
        },
      }
    )
    .then((res) => {
      const {
        // data: { collections },
        data,
      } = res;
      callback({ data });
    })
    .catch((error) => handleError(error, callback));
}

async function getPageDetails({ id, pageNumber, callback }) {
  return client
    .post(
      `${API_PATH.pageDetail}`,
      {
        id,
        pageNumber,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-api-key": API_KEY,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback && callback({ data });
      return data;
    })
    .catch((error) => handleError(error, callback));
}
async function getPresignedDownloadUrl({ bucket, key, callback }) {
  return await client
    .post(
      `resource`,
      {
        bucket,
        key,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const {
        data: { url },
      } = res;
      callback({ url });
      return { url };
    })
    .catch((error) => handleError(error, callback));
}

async function addSongToPlaylist({ id, songId, callback }) {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) return;
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `${API_PATH.playlist}`,
      {
        id,
        songId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      callback({ data });
    })
    .catch((err) => {
      callback({ err });
    })
    .catch((error) => handleError(error, callback));
}

export default {
  createNovelMetadata,
  getNovelsByNovelType,
  createNovelPage,
  getNovelsPagesById,
  getNovelDetailsById,
  getPageDetails,
};
