import { INPUT_TYPE } from "../constants";

export const CREATE_PAGE_CONFIGS = [
  {
    fieldName: "title",
    label: "title",
    inputType: INPUT_TYPE.TEXT,
  },
  {
    fieldName: "pageNumber",
    label: "pageNumber",
    inputType: INPUT_TYPE.NUMBER,
  },
  {
    fieldName: "pageContent",
    label: "pageContent",
    inputType: INPUT_TYPE.TEXT_AREA,
  },
  {
    fieldName: "attachment",
    label: "audio file",
    inputType: INPUT_TYPE.FILE,
    fileTypes: ["mp3", "wav"],
  },
];
