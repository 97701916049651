import React, { useEffect, useRef, useState } from "react";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import { getLocalStorageItem, getUserIdFromLocal, setLocalStorageItem } from "../utils/helperFunctions";
import metadataDB from "../storage/metadataDB";
import novelService from "../services/novelService";

export default function AudioPlayerList(props) {
  const {
    playList = [],
    passAudioInstanceToParent,
    setCurrentPlaying,
    coverSrc,
    getAudioSrcById,
    isCurrentlyPlaying,
    setIsCurrentlyPlaying,
    setPlayHistory,
    currentPlaying,
  } = props;
  const musicPlayerRef = useRef(null);
  const [defaultVolume, setDefaultVolume] = useState(getLocalStorageItem("defaultVolume") || 0.5);
  const [defaultPlayMode] = useState(getLocalStorageItem("defaultPlayMode") || "orderLoop");
  useEffect(() => {
    setLocalStorageItem("defaultVolume", defaultVolume);
  }, [defaultVolume]);
  return (
    <>
      <ReactJkMusicPlayer
        autoPlay={false}
        ref={musicPlayerRef}
        defaultPlayMode={defaultPlayMode}
        defaultVolume={defaultVolume}
        onAudioPause={(info) => {
          setIsCurrentlyPlaying(false);
        }}
        onAudioPlay={(audioInfo) => {
          if (currentPlaying.pageNumber != audioInfo.pageNumber) {
            setCurrentPlaying(audioInfo);
          }
          setIsCurrentlyPlaying(true);
          // const { id, pageNumber } = audioInfo;
          // const userId = getUserIdFromLocal();
          // console.log(
          //   `TimeNow: ${new Date().toISOString()}, PLAYING novelId: ${id}, pageNumber: ${pageNumber}, userId: ${userId}`
          // );
        }}
        onAudioEnded={(currentPlayId, audioLists, audioInfo) => {}}
        onAudioProgress={(audioInfo) => {
          const { currentTime, pageNumber, id, name, duration } = audioInfo;
          const playingPrpgress = Math.floor((currentTime / duration) * 100);
          // console.log({ playingPrpgress }, playingPrpgress % 5);
          if (playingPrpgress % 2 === 1) {
            const record = {
              id: `${id}#${pageNumber}`,
              pageNumber,
              lastModifiedDate: new Date().toISOString(),
              novelId: id,
              currentTime,
              duration,
            };
            setPlayHistory((prev) => {
              const filteredList = prev.filter((item) => item.id !== `${id}#${pageNumber}`);
              filteredList.push(record);
              return filteredList;
            });
            metadataDB.putItemToPlayHistory(record);
          }
        }}
        onPlayIndexChange={(playIndex) => {
          // console.log("onPlayIndexChange", playIndex);
          setCurrentPlaying(playList[playIndex]);
        }}
        onAudioVolumeChange={(volume) => {
          setDefaultVolume(Math.pow(volume, 1 / 2));
        }}
        onPlayModeChange={(mode) => {
          // setLocalStorageItem("defaultPlayMode", mode);
        }}
        audioLists={playList.map((song) => {
          const { title, artist = "", attachmentUrl, id, pageNumber } = song;
          return {
            ...song,
            name: title,
            musicSrc: attachmentUrl,
            singer: "小笙",
            cover: coverSrc,
            id,
            pageNumber,
          };
        })}
        showDownload={false}
        getAudioInstance={(instance) => {
          passAudioInstanceToParent(instance);
        }}
        clearPriorAudioLists={!isCurrentlyPlaying}
        theme="auto"
        showLyric={true}
        getAudioSrcById={getAudioSrcById}
        drag={false}
        quietUpdate={true}
        defaultPosition={{ top: "50%", right: "0" }}
        glassBg={true}
        showMiniProcessBar={true}
        showMediaSession={true}
        locale="zh_CN"
      />
    </>
  );
}
