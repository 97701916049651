import "./css/App.css";

import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import { ToastContainer } from "react-toastify";

import AuthenticateWrapper from "./components/authenticateWrapper";
import Header from "./components/header";
import Login from "./components/Login";
import AppContext from "./context/app-context";
import AdminPage from "./page/adminPage";
import HomePage from "./page/homePage";
import SongHomePage, { PAGINATION_SIZE } from "./page/novelDetailPage";
import { UploadSongPage } from "./page/uploadSongPage";
import userProfileService from "./services/userProfileService";
import WebStats from "./services/webStats";
import audioStorage from "./storage/localFileStorage";
import { getLocalStorageItem, getUserIdFromLocal, setLocalStorageItem } from "./utils/helperFunctions";
import NovelDetailPage from "./page/novelDetailPage";
import AboutPage from "./page/aboutPage";
import metadataDB from "./storage/metadataDB";
import { AdComponent } from "./components/AdComponent";

export const APP_ROUTE_LIST = [
  {
    path: "/",
    element: <HomePage />, // <AuthenticateWrapper components={<TicTacToe />} />,
  },
  {
    path: "/create",
    element: <AdminPage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/create/:id",
    element: <AdminPage />,
  },
  {
    path: "/details/:id",
    element: <NovelDetailPage />,
  },
  // {
  //   path: "/:novelType",
  //   element: <HomePage />,
  // },
  {
    path: "/uploadSong",
    element: <UploadSongPage />,
    title: "Upload a song to the system",
    description: " ",
  },
  {
    path: "/online-songs",
    element: <SongHomePage />,
    title: "Online Song collection",
    description: "Download songs and play it offline",
  },
  {
    path: "/login",
    element: <AuthenticateWrapper components={<Login />} />,
  },
  // {
  //   path: "/profile",
  //   element: <AuthenticateWrapper components={<ProfilePage />} />,
  // },
];
function App() {
  const [loading, setLoading] = useState(false);
  const [appRefresh, setAppRefresh] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const [downloadedSongs, setDownloadedSongs] = useState([]);
  const [novelListTypeMap, setNovelListTypeMap] = useState({});
  const [cachedNovelList, setCachedNovelList] = useState([]);
  const [myPlayHistory, setMyPlayHistory] = useState([]);
  const [userId, serUserId] = useState("");

  useEffect(() => {
    async function getAllLocalData() {
      const allhistory = await metadataDB.getLocalPlayHistory();
      const novelHistoryMap = allhistory
        .sort((a, b) => new Date(b.insertedDate).getTime() - new Date(a.insertedDate).getTime())
        .reduce((acc, current) => {
          const { novelId } = current;
          if (acc[novelId]) {
            if (acc[novelId].length >= 10) {
              const { id } = current;
              metadataDB.deleteItemFromNovelHistory(id);
              return acc;
            }
            acc[novelId] = [...acc[novelId], current];
          } else {
            acc[novelId] = [current];
          }
          return acc;
        }, {});

      // const loaclList = await metadataDB.getLocalNovelList();
      const historyItemMapping = [];
      const novelIds = Object.keys(novelHistoryMap);
      for (var index = 0; index < novelIds.length; index += 1) {
        const key = novelIds[index];
        const localItem = await metadataDB.getNovelMetadataById(key);
        if (localItem) {
          const [{ pageNumber }] = novelHistoryMap[key];
          const localPaginationPage = Math.floor((pageNumber - 1) / PAGINATION_SIZE) + 1;
          const { id } = localItem;
          historyItemMapping.push({
            ...localItem,
            id: `${id}?page=${localPaginationPage}`,
            localPaginationPage,
            playHistory: novelHistoryMap[key],
          });
        }
      }
      setMyPlayHistory(historyItemMapping);
      // console.log(novelHistoryMap);
    }
    getAllLocalData();
  }, []);
  useEffect(() => {
    userProfileService.getUserProfile({
      userId,
      callback: ({ userProfile: profile }) => {
        if (profile) {
          setUserProfile(profile);
          const { userId } = profile || {};
          setLocalStorageItem("userId", { userId });
        }
      },
    });
  }, [appRefresh]);
  useEffect(() => {
    const uid = getUserIdFromLocal();
    serUserId(uid);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <AppContext.Provider
          value={{
            loading,
            setLoading,
            userProfile,
            setAppRefresh,
            downloadedSongs,
            setDownloadedSongs,
            novelListTypeMap,
            setNovelListTypeMap,
            myPlayHistory,
          }}
        >
          <>
            {loading && (
              <div className="fadeMe">
                <PacmanLoader color="#36d7b7" loading={loading} className={"spinner"} />
              </div>
            )}
            <Header />
            <WebStats />
            <div className="container">
              {/* <Spinner loading={loading} />{" "} */}
              <Routes>
                {APP_ROUTE_LIST.map((item) => {
                  return <Route key={item.path} path={item.path} element={item.element} />;
                })}
              </Routes>
            </div>
            <AdComponent />
          </>
        </AppContext.Provider>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
