import { AiFillPlayCircle } from "react-icons/ai";
import { IoIosSkipBackward, IoIosSkipForward } from "react-icons/io";

export default function AudioControlBar(props) {
  const { prev, play, next } = props;
  return (
    <div className="novel-audio-controler">
      <IoIosSkipBackward className="novel-audio-item" onClick={prev} />
      <AiFillPlayCircle className="novel-audio-item" onClick={play} />
      <IoIosSkipForward className="novel-audio-item" onClick={next} />
    </div>
  );
}
