import { Auth } from "aws-amplify";
import axios from "axios";
import { toast } from "react-toastify";
import { isUserLogined } from "../context/useAuth";
import { getUserJwtToken } from "../utils/helperFunctions";

const USER_SERVICE_URL = "https://user.service.crz97.com";

const client = axios.create({ baseURL: USER_SERVICE_URL });

const API_PATH = {
  profile: "/profile",
  tripsplit: "/tripsplit",
};

async function getUserProfile({ callback, userId }) {
  if (!(await isUserLogined())) {
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();

  const jwtToken = getUserJwtToken(user);
  client
    .get(`${API_PATH.profile}?userId=${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: jwtToken,
      },
    })
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { userProfile = {} },
      } = res;
      callback({ userProfile });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

async function updateUserProfile({ displayName, userId, callback }) {
  if (!(await isUserLogined())) {
    alert("You need to login first!!");
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .put(
      `${API_PATH.profile}`,
      {
        displayName,
        userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { userProfile = {} },
        data,
      } = res;
      // console.log(data);
      callback({ userProfile });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

async function updateTripCost({ item, callback }) {
  if (!(await isUserLogined())) {
    alert("You need to login first!!");
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .post(
      `${API_PATH.tripsplit}`,
      {
        ...item,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { records = [] },
        data,
      } = res;
      console.log(data);
      callback({ records });
    })
    .catch((error) => {
      console.error("Error:", error);
      const {
        response: { data, status },
      } = error;
      if (status === 400) {
        alert(
          `Sorry, you don't have permission to update this record. feel free to create your own by visiting the home page!`
        );
      }
      callback({ error });
    });
}

async function getAllTripCost({ callback }) {
  if (!(await isUserLogined())) {
    // alert("You need to login first!!");
    callback({});
    return;
  }
  const user = await Auth.currentAuthenticatedUser();
  const jwtToken = getUserJwtToken(user);
  client
    .put(
      `${API_PATH.tripsplit}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwtToken,
        },
      }
    )
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { records = [] },
        data,
      } = res;
      // console.log(data);
      callback({ records });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

async function getTripCostById({ id, callback }) {
  // if (!(await isUserLogined())) {
  //   alert("You need to login first!!");
  //   return;
  // }
  // const user = await Auth.currentAuthenticatedUser();
  // const jwtToken = getUserJwtToken(user);
  client
    .get(`${API_PATH.tripsplit}?id=${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwtToken,
      },
    })
    .then((res) => {
      //   console.log("Success:", res);
      const {
        data: { records = [] },
        data,
      } = res;
      // console.log(data);
      callback({ records });
    })
    .catch((error) => {
      console.error("Error:", error);
      callback({ error });
    });
}

export default {
  getUserProfile,
  updateTripCost,
  updateUserProfile,
  getTripCostById,
  getAllTripCost,
};
