import { generateRandomId } from "../utils/helperFunctions";

export default function NovelPageList(props) {
  const { displayRows, otherComponent } = props;
  return (
    <div className="novel-pages-list">
      <div>
        {displayRows.map((row = [], index) => {
          const [{ currentlyPlaying }] = row;
          return (
            <div
              key={generateRandomId()}
              style={{
                display: "flex",
                borderStyle: "groove",
                backgroundColor: currentlyPlaying ? "lightgreen" : undefined,
                fontSize: "18px",
              }}
            >
              {row.map((item, index) => {
                const { value, onClick = () => {} } = item;
                const widthSize = ["80%", "20%"];
                return (
                  <div
                    key={generateRandomId()}
                    onClick={onClick}
                    style={{
                      margin: "0 8px",
                      width: widthSize[index],
                      cursor: "pointer",
                    }}
                  >
                    {value}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      {otherComponent}
      <div style={{ height: "62px" }}></div>
    </div>
  );
}
