import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";

import { INPUT_TYPE } from "../constants";

export const EditableModal = (props) => {
  const { actionLabel, actionOnClick, title, configs = [] } = props;
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    setData(
      configs.reduce(
        (accumulate, item) => ({
          ...accumulate,
          [item.fieldName]: item.value || "",
        }),
        {}
      )
    );
  }, [showModal]);
  const onChange = (e) => {
    const newData = { ...data, [e.target.id]: e.target.value };
    setData((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  };

  var modalBody = (
    <div>
      <Form>
        {configs.map((item) => {
          // const item = configs.find((config) => config.fieldName == attr);
          const { fieldName, label, inputType } = item;

          switch (inputType) {
            case INPUT_TYPE.TEXT:
              return (
                <div key={fieldName}>
                  <Form.Label>{label}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <Form.Control
                      type="text"
                      id={fieldName}
                      value={data[fieldName]}
                      onChange={onChange}
                    />
                  </div>
                </div>
              );
            case INPUT_TYPE.NUMBER:
              return (
                <div key={fieldName}>
                  <Form.Label>{label}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <Form.Control
                      type="number"
                      id={fieldName}
                      value={data[fieldName]}
                      onChange={onChange}
                    />
                  </div>
                </div>
              );
            case INPUT_TYPE.TEXT_AREA:
              return (
                <div key={fieldName}>
                  <Form.Label>{label}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      id={fieldName}
                      value={data[fieldName]}
                      onChange={onChange}
                    />
                  </div>
                </div>
              );
            case INPUT_TYPE.SELECT:
              return (
                <div key={fieldName}>
                  <Form.Label>{label}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <Form.Select
                      aria-label={label}
                      id={fieldName}
                      value={data[fieldName]}
                      onChange={onChange}
                    >
                      {item.enumValues.map((val) => {
                        return (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </div>
              );
            case INPUT_TYPE.FILE:
              return (
                <div key={fieldName}>
                  <Form.Label>{label}</Form.Label>
                  <div style={{ display: "flex" }}>
                    <FileUploader
                      handleChange={(file) => {
                        console.log(file);
                        setData({
                          ...data,
                          [fieldName]: file,
                        });
                      }}
                      multiple={false}
                      hoverTitle={"Drop the videos here"}
                      types={item.fileTypes}
                    />
                  </div>
                </div>
              );
          }
          return (
            <div className="form-group" key={fieldName}>
              NOT SUPPORTED INPUT
            </div>
          );
        })}
      </Form>
    </div>
  );
  return !showModal ? (
    <div>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        {title}
      </Button>
    </div>
  ) : (
    <div>
      <Modal show={showModal}>
        <Modal.Header closeButton onClick={() => setShowModal(false)}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="dark"
            onClick={() => {
              actionOnClick(data);
              setShowModal(false);
              setData({});
            }}
          >
            {actionLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
