import Pagination from "react-bootstrap/Pagination";
import { generateRandomId } from "../utils/helperFunctions";
import { Button, Form } from "react-bootstrap";
import { useState } from "react";

const EDGE_PAGE_SIZE = 3;
const MIDDLE_PAGE_SIZE = 4;
export default function PaginationWrapper(props) {
  const { size, active, onChange } = props;

  const [jumpToPage, setJumpToPage] = useState("");
  const renderEllipsis = (onClickHandler) => {
    return <Pagination.Ellipsis key={generateRandomId()} onClick={onClickHandler} />;
  };

  const renderFirstPages = () => {
    const firstPagesToShow = EDGE_PAGE_SIZE; // Number of first pages to show

    const paginationItems = [];
    for (let i = 0; i < Math.min(firstPagesToShow, size); i++) {
      paginationItems.push(
        <Pagination.Item key={"firstPage_" + i} active={i === active} onClick={() => onChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }

    if (active >= firstPagesToShow + EDGE_PAGE_SIZE) {
      paginationItems.push(renderEllipsis(() => onChange(Math.floor(size / EDGE_PAGE_SIZE))));
    }

    return paginationItems;
  };

  const renderLastPages = () => {
    const lastPagesToShow = EDGE_PAGE_SIZE; // Number of last pages to show

    const paginationItems = [];
    for (let i = size - lastPagesToShow; i < size; i++) {
      if (i > 0) {
        paginationItems.push(
          <Pagination.Item key={"lastPage_" + i} active={i === active} onClick={() => onChange(i)}>
            {i + 1}
          </Pagination.Item>
        );
      }
    }

    return paginationItems;
  };

  const renderMiddlePages = () => {
    const middlePagesToShow = MIDDLE_PAGE_SIZE; // Number of middle pages to show

    const paginationItems = [];
    const firstPage = Math.max(EDGE_PAGE_SIZE, active - Math.floor(middlePagesToShow / EDGE_PAGE_SIZE));
    const lastPage = Math.min(size - MIDDLE_PAGE_SIZE, active + Math.floor(middlePagesToShow / EDGE_PAGE_SIZE));

    for (let i = firstPage; i <= lastPage; i++) {
      paginationItems.push(
        <Pagination.Item key={"middlePage_" + i} active={i === active} onClick={() => onChange(i)}>
          {i + 1}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <Pagination>
        {renderFirstPages()}
        {renderMiddlePages()}
        <div style={{ display: "flex", flexWrap: "wrap", width: "100px" }}>
          <Form.Control
            type="number"
            placeholder="页数"
            value={jumpToPage}
            onChange={(e) => setJumpToPage(e.target.value)}
          />
          <Button
            onClick={() => {
              onChange(Number(jumpToPage - 1));
              setJumpToPage("");
            }}
          >
            跳转
          </Button>
        </div>
        {size > 5 && renderEllipsis(() => onChange(Math.floor(size / EDGE_PAGE_SIZE)))}
        {renderLastPages()}
      </Pagination>
    </div>
  );
}
