import { Slider } from "@mui/material";
import { generateRandomId } from "../utils/helperFunctions";

export default function PageContentDisplayer(props) {
  const { setFontSize, pageContent, displayMulu, fontSize } = props;
  return (
    !displayMulu &&
    pageContent && (
      <div>
        <div>
          <div style={{ margin: "0 30px" }}>
            <Slider
              aria-label="Custom marks"
              defaultValue={30}
              onChange={(event) => {
                setFontSize(12 + Math.floor((event.target.value / 100) * 25));
              }}
              step={5}
              valueLabelDisplay="auto"
              marks={[0, 20, 50, 80, 100].map((item) => {
                return { label: item, value: item };
              })}
            />
          </div>
        </div>
        <div style={{ fontSize: `${fontSize}px` }}>
          {" "}
          {pageContent.split("\n").map((line) => {
            return <p key={generateRandomId()}>{line}</p>;
          })}
        </div>
      </div>
    )
  );
}
