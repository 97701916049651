// db.js
import Dexie from "dexie";
import localFileStorage from "./localFileStorage";

const metadataDB = new Dexie("metadataDB");

metadataDB.version(1).stores({
  novelMetaData: "&id, title, author, description, novelType, lastModifiedDate",
  novelPageData: "&id, pageNumber, title, pageContent, novelId",
  playHistory:
    "&id, pageNumber, lastModifiedDate, novelId, currentTime, duration",
});

async function putItemToNovelMetadata(item) {
  const insertedDate = new Date().toISOString();
  return metadataDB.novelMetaData.put({ ...item, insertedDate });
}

async function putItemToNovelPageData(item) {
  const insertedDate = new Date().toISOString();
  return metadataDB.novelPageData.put({ ...item, insertedDate });
}
async function putItemToPlayHistory(item) {
  const insertedDate = new Date().toISOString();
  return metadataDB.playHistory.put({ ...item, insertedDate });
}

async function getNovelMetadataById(id) {
  var attachmentUrl = await localFileStorage.getBlobFromLocal(id);
  if (attachmentUrl) {
    attachmentUrl = URL.createObjectURL(attachmentUrl);
  }
  const metadataItem = await metadataDB.novelMetaData.get(id);
  return { ...metadataItem, attachmentUrl };
}

async function getNovelPageMetaById(id) {
  return metadataDB.novelPageData.get(id);
}

async function getNovelPageById(id) {
  return metadataDB.novelPageData
    .where("novelId")
    .equalsIgnoreCase(id)
    .toArray();
}

async function getLocalNovelList() {
  return metadataDB.novelMetaData.toArray();
}

async function deleteAllCaches() {
  const pages = (await metadataDB.novelPageData.toArray()) || [];
  const metas = (await metadataDB.novelMetaData.toArray()) || [];
  const histories = (await metadataDB.playHistory.toArray()) || [];
  pages.forEach((page) => {
    const { id } = page;
    localFileStorage.deleteBlobFromLocal(id);
    deleteItemFromPagedata(id);
  });
  metas.forEach((nov) => {
    const { id } = nov;
    localFileStorage.deleteBlobFromLocal(id);
    deleteItemFromNovelMetadata(id);
  });
  histories.forEach((nov) => {
    const { id } = nov;
    deleteItemFromNovelHistory(id);
  });
  return pages.length + metas.length + histories.length;
}
async function getLocalPlayHistory(id) {
  if (id) {
    return metadataDB.playHistory
      .where("novelId")
      .equalsIgnoreCase(id)
      .toArray();
  }
  return metadataDB.playHistory.toArray();
}

async function deleteItemFromNovelMetadata(id) {
  return metadataDB.novelMetaData.delete(id);
}
async function deleteItemFromNovelHistory(id) {
  // localFileStorage.deleteBlobFromLocal(id);
  return metadataDB.playHistory.delete(id);
}
async function deleteItemFromPagedata(id) {
  return metadataDB.novelPageData.delete(id);
}

function getPageItemId(novelId, pageNumber) {
  return `${novelId}#${pageNumber}`;
}
export default {
  putItemToNovelMetadata,
  putItemToNovelPageData,
  putItemToPlayHistory,
  getNovelPageById,
  getLocalNovelList,
  getLocalPlayHistory,
  deleteItemFromNovelMetadata,
  deleteItemFromNovelHistory,
  getPageItemId,
  getNovelMetadataById,
  deleteAllCaches,
  getNovelPageMetaById,
};
