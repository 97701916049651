import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../context/app-context";
import { getLocalStorageItem, getUserJwtToken } from "../utils/helperFunctions";
import { FileUploader } from "react-drag-drop-files";
import { Button, ProgressBar } from "react-bootstrap";
import { EditableModal } from "../components/editableModal";
import { CREATE_NOVEL_CONFIGS } from "../configs/createNovelConfig";
import novelService from "../services/novelService";
import { CREATE_PAGE_CONFIGS } from "../configs/createPageConfig";
import localFileStorage from "../storage/localFileStorage";
import NovelInfoDisplay from "../widgets/novelInfoDisplay";
// import collectionService from "../services/collectionService";

export default function AdminPage(props) {
  const { id } = useParams("id");
  const { setLoading, userProfile } = useContext(AppContext);
  const [refresh, setRefresh] = useState(0);
  const [collection, setCollection] = useState({});
  const [uploadStatus, setUploadStatus] = useState({});
  const [itemRecords, setItemRecords] = useState([]);
  const [novelDetails, setNovelDetails] = useState({});
  console.log(id);
  const { title, description } = collection || {};
  const filesUploadCallback = ({ id, value }) => {
    console.log(`${id} uploaded ${value}%`);
    uploadStatus[id] = value;
    console.log(uploadStatus);
    setUploadStatus({ ...uploadStatus });
  };
  useEffect(() => {
    async function getLocalThenOnlineDetails() {
      novelService.getNovelDetailsById({
        id,
        callback: async ({ data }) => {
          if (data) {
            const { item } = data;
            const {
              id,
              attachmentUrl,
              title: titleOnline,
              attachment: { type },
            } = item;
            document.title = `听笙阁 ${titleOnline}`;
            const coverImgSrc = await localFileStorage.getFileOrDownloadedUrl(
              id,
              attachmentUrl,
              type,
              () => {}
            );
            item[attachmentUrl] = coverImgSrc;
            setNovelDetails((prev) => ({ ...prev, ...item }));
          }
        },
      });
    }
    if (id) {
      getLocalThenOnlineDetails();
    }
  }, [id]);
  return (
    <div>
      <h2> {title} </h2>
      <p>{description}</p>
      {/* <div>Create a new item {id}</div> */}
      {
        <EditableModal
          title={"创建新的小说"}
          configs={CREATE_NOVEL_CONFIGS}
          actionLabel={"Create"}
          actionOnClick={(data) => {
            const { userId } = userProfile;
            novelService.createNovelMetadata({
              ...data,
              userId,
              callback: async ({ data }) => {
                console.log(data);
                const { id } = data;
                console.log(`"id":"${id}",`);
              },
            });
          }}
        />
      }

      {id && (
        <div>
          <NovelInfoDisplay novelDetails={novelDetails} />
          <EditableModal
            title={"上传最新的章节"}
            configs={CREATE_PAGE_CONFIGS}
            actionLabel={"Create"}
            actionOnClick={(data) => {
              const { userId } = userProfile;
              if (id) {
                const { pageNumber } = data;
                if (pageNumber <= novelDetails.lastestPageNumber) {
                  alert(
                    `这一集已经更新， 请更新${
                      novelDetails.lastestPageNumber + 1
                    }以后的章节!!`
                  );
                  return;
                }
                setLoading(true);
                novelService.createNovelPage({
                  ...data,
                  id,
                  userId,
                  callback: ({ data }) => {
                    console.log(data);
                    setLoading(false);
                  },
                });
              }
            }}
          />
        </div>
      )}
      <div>
        {itemRecords.map((item) => {
          const { title } = item;
          return <div key={title}>{title}</div>;
        })}
      </div>
    </div>
  );
}
